&--d {
  .select-icons {
    .p500-ui-form-field__inner {
      .p500-ui-form-field__wrapper {
        background: $g-color-white;
        border: 1px solid $g-color-gray;

        .p500-ui-form-field__background-icon {
          color: $g-color-black;
        }
        .p500-ui-form-field__background-icon:hover {
          border: 1px solid $g-color-gray;
        }
        .p500-ui-form-field__active {
          background: $g-color-gray;
        }
      }
    }
  }

  #{$root} {
    &__section {
      margin-bottom: 14px;

      .-editor-with-limited-height {
        .ql-container {
          max-height: 300px;
          overflow: auto;
        }
      }

      &.-full-width {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        width: 100%;
      }
    }

    &__section-splitter {
      width: inherit;
      margin-left: 20px;
      margin-right: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .-splitter-body {
        width: inherit;
        height: 1px;
        border: 1px solid $g-color-gray;
      }
    }

    &__info-block {
      padding: var(--Space-400, 12px);
      border-radius: var(--Radius-large, 8px);
      background: var(--blue, #ecf4fc);
    }
  }
}
