&--card-settings {
  display: flex;
  flex-direction: row;

  .-widget {
    row-gap: 12px;
  }

  @media (max-width: 1800px) and (min-width: 1280px), (max-width: 768px) {
    &.-adaptive {
      flex-direction: column;

      #{$root} {
        &--column-in-remove {
          width: 100%;

          &.-second {
            margin-left: 0;
            margin-top: 10px;
          }

          &.-with-button {
            margin-top: 20px;
            flex-direction: row;
          }
        }

        &--extra-select {
          width: 95%;
        }

        &--remove-extra-button {
          &.-mobile {
            display: flex;
          }
          &.-desktop {
            display: none;
          }
        }
      }
    }
  }

  #{$root} {
    &--column {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &--column-in-remove {
      color: rgba($g-color-dark-blue, 0.7);
      display: flex;
      flex-direction: column;
      width: 49%;

      &.-second {
        margin-left: 10px;
      }
    }

    &--label-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .p500-ui-form-field--checkbox {
        .p500-ui-form-field__label {
          @include font-body-4;
          color: rgba($g-color-dark-blue, 0.7);
        }

        .p500-ui-form-field__label:before {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }

      .-label {
        @include font-body-4;

        &.-required:after {
          color: #f70f0f;
          opacity: 0.6;
          margin-left: 2px;
          content: '*';
        }
      }
    }

    &--goal-selects {
      width: 87%;
      color: rgba($g-color-dark-blue, 0.7);
      display: flex;
      flex-direction: column;
      justify-content: end;

      &.-with-remove {
        width: 92%;
      }
    }

    &--remove-extra-button,
    &--goal-add-button {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      justify-content: end;

      &.-mobile {
        display: none;
      }
    }
  }
}
