$subtitleMargin: 8px;
$titleMargin: 8px;

$scroll: 10px;
$refreshBtn: 30px;
$plusBtn: 35px;
$leftRightGap: 10px;
$hint: 35px;
$sideBar: 252px + 70px;

@function subtitlesMaxWidth($paddings, $rightHeader, $titlesCount, $useHint, $useRefreshBtn, $withSidebar) {
  $arrow: 14px;
  $arrowMargin: 10px;

  @return calc(
    (
        100vw - 2 * $paddings - $scroll - $titleMargin * ($titlesCount - 1) - $arrow - $arrowMargin - $subtitleMargin - $plusBtn -
          $refreshBtn * $useRefreshBtn - $useHint * $hint - $leftRightGap - $rightHeader - $withSidebar * $sideBar
      ) / $titlesCount
  );
}

@function titleMaxWidth($paddings, $rightHeader, $withSidebar) {
  @return calc(
    (
      100vw - 2 * $paddings - $scroll - $titleMargin - $plusBtn - $refreshBtn - $hint - $leftRightGap - $rightHeader - $withSidebar *
        $sideBar
    )
  );
}

.c-table-header {
  background-color: $g-color-white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(mx) {
    min-width: 0;
  }

  &.c-table-header--with-subtitle {
    .c-table-header {
      &__title {
        @include media-breakpoint-up(mx) {
          max-width: subtitlesMaxWidth(18px, 270px, 2, 1, 1, 0);
        }

        @include media-breakpoint-up(lg) {
          max-width: subtitlesMaxWidth(32px, 270px, 2, 1, 1, 1);
        }

        @include media-breakpoint-up(xl) {
          max-width: subtitlesMaxWidth(32px, 480px, 2, 1, 1, 1);
        }

        @include media-breakpoint-up(xxl) {
          max-width: subtitlesMaxWidth(32px, 515px, 2, 1, 1, 1);
        }
      }

      &__subtitle-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
          max-width: subtitlesMaxWidth(15px, 185px, 1, 0, 0, 0);
        }

        @include media-breakpoint-up(sm) {
          max-width: subtitlesMaxWidth(15px, 185px, 1, 1, 0, 0);
        }

        @include media-breakpoint-up(md) {
          max-width: subtitlesMaxWidth(18px, 225px, 1, 1, 1, 0);
        }

        @include media-breakpoint-up(mx) {
          max-width: subtitlesMaxWidth(18px, 270px, 2, 1, 1, 0);
        }

        @include media-breakpoint-up(lg) {
          max-width: subtitlesMaxWidth(32px, 270px, 2, 1, 1, 1);
        }

        @include media-breakpoint-up(xl) {
          max-width: subtitlesMaxWidth(32px, 480px, 2, 1, 1, 1);
        }

        @include media-breakpoint-up(xxl) {
          max-width: subtitlesMaxWidth(32px, 515px, 2, 1, 1, 1);
        }
      }
    }
  }

  &.c-table-header--two-lines {
    .c-table-header__title {
      @include media-breakpoint-up(xxxl) {
        max-width: titleMaxWidth(32px, 730px, 1);
      }
    }

    &.c-table-header--with-subtitle {
      .c-table-header {
        &__title,
        &__subtitle-value {
          @include media-breakpoint-up(xxxl) {
            max-width: subtitlesMaxWidth(32px, 620px, 2, 1, 1, 1);
          }
        }
      }
    }
  }

  &__title {
    @include font-headline-1;
    margin-right: $titleMargin;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include media-breakpoint-up(mx) {
      max-width: titleMaxWidth(18px, 370px, 0);
    }

    @include media-breakpoint-up(lg) {
      max-width: titleMaxWidth(32px, 370px, 1);
    }

    @include media-breakpoint-up(xl) {
      max-width: titleMaxWidth(32px, 550px, 1);
    }

    @include media-breakpoint-up(xxl) {
      max-width: titleMaxWidth(32px, 615px, 1);
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    &.-gray {
      color: rgba($g-color-secondary, 0.3);
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    @include font-headline-1;
    margin-right: $subtitleMargin;
  }

  .p500-ui-button {
    height: 30px;
  }

  &__multi-action {
    margin-right: 10px;

    .p500-ui-button--has-icon.icon-right {
      padding-left: 10px;

      .p500-ui-button__icon {
        margin-left: 0;
      }
    }
  }

  &__content-left {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    width: auto;
    flex-wrap: wrap;

    .p500-ui-circled-button .p500-ui-icon-control-refresh-bold {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      .p500-ui-circled-button .p500-ui-icon-control-refresh-bold {
        display: inherit;
      }
    }
  }

  &__content-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;
    gap: 6px;
    flex-grow: 1;
    justify-content: end;
  }

  &__content-bottom {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 12px;

    .c-table-header__divider {
      display: none;
      min-width: 2px;
      flex-basis: auto;
      flex-grow: 1;

      @media (min-width: 650px) {
        display: flex;
      }
    }
  }

  &__datepicker {
    margin-left: auto;
  }

  &__pagination {
    @include media-breakpoint-down(xs) {
      margin-left: auto;
    }
  }

  &__reset-filters {
    margin-right: 28px;
  }

  .search-wrapper {
    gap: 5px;
    display: grid;
    grid-template-columns: minmax(120px, 2fr) minmax(110px, 3fr);

    .p500-ui-form-field__values {
      display: none;
    }

    &--dropdown {
      grid-template-columns: 1fr;
      min-width: 250px;
    }

    &--quick-filters-select {
      ng-dropdown-panel {
        width: auto;
        min-width: 100%;
      }
    }
  }

  &__search {
    display: block;
    margin-bottom: $y-main-content-mobile-padding;
    width: auto;
    order: 1;
    flex-grow: 3;
    max-width: 400px;

    @include media-breakpoint-up(sm) {
      display: none;
      margin-bottom: 0;
      order: 0;
    }

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  &__settings,
  &__filters {
    .is-active {
      color: $g-color-secondary;
    }
  }

  &__settings-inner {
    right: 32px;
    z-index: 15;
    display: none !important;

    &.is-open {
      display: block !important;
    }
  }

  &__hint-button {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__action {
    margin-right: 12px;
    height: 28px;
  }

  &__filter-values {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    white-space: nowrap;
    overflow-x: auto;

    @include media-breakpoint-up(md) {
      margin-top: 10px;
      margin-bottom: 18px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      border-radius: $g-value-border-radius-xl;
      padding: 18px;
      background-color: rgba($g-color-primary, 0.05);
    }
  }

  &__filter-values-mini {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-wrap: wrap;
    gap: 6px;
  }
}
