&--d {
  max-width: 776px;

  #{$root} {
    &__inner {
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0;
      height: 100%;
      overflow-y: auto;
      box-shadow: 0 20px 100px 0 rgba(0, 0, 0, 0.15);
      background-color: $g-color-white;

      @include media-breakpoint-up(sm) {
        overflow-y: unset;
        margin-top: 100px;
        margin-bottom: 100px;
        border-radius: 20px;
        height: auto;
      }
    }

    &__close {
      position: absolute;
      font-size: 24px;
      line-height: 1em;
      display: block;
      right: 18px;
      top: 18px;
      z-index: 21;
      cursor: pointer;
      opacity: 0.3;
      @include h-general-transition(opacity);

      &:hover {
        opacity: 1;
      }
    }

    &__head {
      margin-bottom: 10px;
      padding: 20px 15px 10px;
      position: sticky;
      top: 0;
      left: 0;
      background-color: $g-color-white;
      z-index: 20;

      @include media-breakpoint-up(sm) {
        position: unset;
        background-color: unset;
        padding: 30px 38px 0;
        margin-bottom: 20px;
      }
    }

    &__icon {
      margin-right: 6px;
      font-size: 18px;
    }

    &__title {
      @include font-headline-2;
      display: flex;
      align-items: center;
    }

    &__header-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 12px;
      margin-left: 12px;

      &.-pointer {
        cursor: pointer;
      }
    }

    &__hint-icon {
      order: 2;
      font-size: 14px;
      margin: 0 0 10px 6px;
    }

    &__subtitle {
      @include font-body-4;
      color: rgba($g-color-secondary, 0.5);
    }

    &__body {
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 60px;
      @include font-body-3;

      @include media-breakpoint-up(sm) {
        padding-left: 38px;
        padding-right: 38px;
        padding-bottom: 30px;
        margin-bottom: 0;
      }

      &--help-info {
        background-color: rgba($g-color-black, 0.03);
        padding: 14px;
        border-radius: 10px;
      }
    }

    &__body-title {
      font-weight: bold;
      margin-bottom: 18px;
      display: flex;

      .title {
        margin-right: 8px;
        @include font-headline-3;
      }
    }

    &__footer {
      padding: 14px 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;

      @include media-breakpoint-up(sm) {
        z-index: initial;
        position: unset;
        justify-content: flex-end;
        position: unset;
      }

      &--help-info {
        padding: 0 38px 30px;
      }

      .c-button--rounded {
        padding: 0 15px;

        &.-blue {
          padding: 0 4px;
          border: 2px solid transparent;
        }

        &:focus {
          border: 2px solid $g-color-secondary;
          padding: 0 14px;

          &.-blue {
            padding: 0 4px;
          }
        }
      }

      &--info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 12px;

        @media (max-width: 456px) {
          justify-content: center;
        }
      }
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__actions {
      display: flex;
      align-items: center;
    }

    &__action {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.-primary {
    #{$root} {
      &__footer {
        background-color: $g-color-light-blue;
        border-radius: 0;

        @include media-breakpoint-up(sm) {
          background-color: rgba($g-color-light-blue, 0.8);
          border-radius: 0 0 20px 20px;
        }
      }
    }
  }

  &.-red {
    #{$root} {
      &__icon {
        color: $g-color-red;
      }

      &__footer {
        background-color: rgba($g-color-red, 0.08);

        .c-button--rounded {
          &.-blue {
            background-color: $g-color-red;

            .p500-ui-icon-check {
              background-color: rgba($g-color-white, 0.2);

              &:before {
                content: '\e907';
              }
            }
          }
        }
      }
    }
  }

  &.-green {
    #{$root} {
      &__icon {
        color: $g-color-green;
      }
    }
  }

  &.-xsmall {
    @include media-breakpoint-up(sm) {
      width: 356px;
    }
  }

  &.-small {
    max-width: 416px;
  }

  &.-medium {
    max-width: 536px;
  }

  &.-large {
    max-width: 656px;
  }

  &.-preset {
    max-width: 776px;
    #{$root} {
      &__body-title {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .c-link--primary {
          font-size: 20px;
        }
      }
    }
  }

  &.-big {
    max-width: 1062px;
  }
  &.-full-width {
    max-width: 100%;
    height: 100vh;
    .c-modal__inner {
      overflow-y: auto;
    }
  }
}
