&--drilldown-preset-order {
  .preset-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  .list-header {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
  .list-header-number {
    width: 24px;
    text-align: center;
  }
  .list-header-show {
    width: 50px;
    text-align: center;
  }
  .list-header-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
  }
  .list-item {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
  .list-item-left {
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: $g-color-light-blue;
  }
  .list-item-right {
    display: flex;
    padding: 8px 0;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
  }
  .preset-name-container {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
  }
  .preset-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
  }
  .vertical-dots {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .vertical-dots-icon {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .checkbox-container {
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
  }
  .checkbox {
    display: flex;
    height: 16px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }
  .divider {
    width: 474px;
    height: 1px;
    background: #f5f5f5;
  }
}
