.c-modal {
  $root: &;
  display: inline-block;
  text-align: left;
  width: 100%;

  @include media-breakpoint-up(sm) {
    margin-bottom: auto;
  }

  &__date {
    color: rgba($g-color-black, 0.5);
    margin-bottom: 8px;
  }

  &__inner {
    position: relative;
  }

  &__form {
    height: 100%;
  }

  &__rules-tabs {
    display: flex;
    margin-top: 22px;
    margin-bottom: 10px;
  }

  &__rules-tab {
    @include font-headline-4;
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    text-align: center;
    background-color: rgba($g-color-secondary, 0.03);
    border: 1px solid rgba($g-color-secondary, 0.1);
    border-radius: 5px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $g-color-primary;
      border-color: rgba($g-color-primary, 0.3);
    }

    &.-active {
      color: $g-color-primary;
      background-color: $g-color-white;
      border-color: $g-color-primary;
      box-shadow: 0 5px 20px rgba($g-color-secondary, 0.08);
      pointer-events: none;
    }
  }

  &__inline-button {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  // todo: remove after new bulk earnings page deploy
  &__body-text {
    @include font-body-4;
  }

  .p500-ui-modal__body {
    p500-ui-slide-toggle-field {
      .p500-ui-form-field__inner {
        justify-content: flex-start;
      }

      .p500-ui-form-field__hint {
        margin-right: 6px;
        margin-left: -6px;
      }
    }

    gtd-affiliate-access {
      & > .row.col-12 {
        padding: 0;
        margin: 0;

        & > .col-6.c-drag-list__container {
          padding: 0 10px 0 0;
        }

        & > .col-6.c-drag-list__container:last-child {
          padding: 0;
        }
      }
    }

    &.-height-reset {
      height: unset;
    }
  }

  &__hint {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 12px;
    color: $g-color-secondary;
    background-color: $g-natural-light-gray;
    cursor: pointer;
    border: none;

    &:not(:first-child) {
      margin-left: 6px;
    }

    &:hover,
    &.-white-bg:hover {
      color: $g-color-white;
      background-color: $g-color-secondary;
    }

    &.-white-bg {
      background-color: $g-color-white;
    }
  }

  &__submit-button {
    height: 30px;

    &.-loading {
      @include skeleton($g-color-primary, $g-color-primary-hover);
      opacity: 1;
      height: 30px;
    }
  }

  @import 'c-modal--d';
  @import 'c-modal--action-from';
  @import 'c-modal--changelog';
  @import 'c-modal--2fa';
  @import 'c-modal--term';
  @import 'c-modal--new-design';
  @import 'c-modal--report-builder-settings';
  @import 'c-modal--tool';
  @import 'c-modal--deal-info';
  @import 'c-modal--offers';
  @import 'c-modal--info';
  @import 'c-modal--link-generator';
  @import 'c-modal--tree-rule';
  @import 'c-modal--offer-links';
  @import 'c-modal--url-replacer';
  @import 'c-modal--pop-up';
  @import 'c-modal--new-version';
  @import 'c-modal--drilldown-preset';
  @import 'c-modal--affiliate-area-auth-settings';
  @import 'c-modal--drilldown-preset-order';
}
