&--affiliate-area-auth-settings {
  margin-top: 0 !important;
  border-radius: 0 !important;
  height: 100% !important;
  background-color: $g-color-pale-blue !important;
  .c-modal__body {
    padding: 0 0 32px;
  }
  .row {
    margin: 0;
  }
  .p500-ui-form-field--color-select .p500-ui-form-field__default-color {
    width: 100%;
  }
  .settings {
    display: flex;
    width: 499px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 32px;
    background: $g-color-white;
  }
  .settings-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 32px 32px 0 32px;
  }
  .settings-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
  }
  .settings-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
  }

  .settings-control-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  .settings-whitelabel-feature {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #ecf4fc;
  }

  .preview {
    display: flex;
    width: 941px;
    height: 900px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .preview-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 12px;
    background: $g-color-white;
    z-index: 2;
    overflow: hidden;
  }
  .preview-default-image {
    &-top {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      max-width: 100%;
    }
    &-bottom {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;
      max-width: 100%;
    }
  }
  .preview-logo-container {
    display: flex;
    min-width: 300px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    @include media-breakpoint-up(md) {
      max-width: 350px;
    }
  }
  .logo {
    width: 100px;
    height: 100px;
    padding: 21px 24px 12px 24px;
    border-radius: 8px;
    background: $g-color-white;
  }
  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    text-align: center;
  }
  .dashed-border {
    border: 2px dashed $g-color-text-disabled;
  }
  .preview-registration-form {
    display: flex;
    min-width: 300px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    flex: 1 0 0;
    background: #f2f2f2;
  }
  .preview-buttons {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
  .preview-button-sign-in {
    display: flex;
    height: 18px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
  }

  .preview-copyright {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    z-index: 2;
  }
  .close {
    font-size: 24px;
    cursor: pointer;
    opacity: 0.3;
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 2;
    @include h-general-transition(opacity);
    &:hover {
      opacity: 1;
    }
  }
}
