&--dashboard-link-generator {
  /*override*/
  .ng-value {
    display: block;
    padding-right: 10px;
    width: 100%;
  }

  .ng-dropdown-panel-items .ng-option .ng-option-label {
    display: block;
  }

  span.ng-arrow-wrapper {
    font-family: 'platform500' !important;

    &::before {
      @include font-headline-5;
      content: '\eb41' !important;
      color: $g-color-primary;
    }
  }

  .c-widget__body {
    overflow: unset;
  }

  /**/

  &__header {
    padding: 40px 28px;
    display: flex;
    align-items: center;
    gap: 14px;

    &__right {
      margin-top: 22px;
    }

    &__left {
      flex: 1;

      .input-has-value {
        input {
          &,
          &:hover,
          &:active,
          &:focus {
            border: 1px solid $g-color-primary;
          }
        }
      }

      .c-form-field--d {
        margin-bottom: 0;
      }
    }
  }

  &__main {
    padding: 40px 28px;
    background: rgba(#000e4b, 0.05);
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .left,
    .right {
      flex: 1;
      white-space: nowrap;

      &__header {
        margin-bottom: 18px;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba($g-color-secondary, 0.05);
        display: flex;
        align-items: center;

        .c-widget__title {
          @include font-headline-4;
        }
      }
    }

    .right {
      &__header {
        padding-bottom: 8px;
      }
    }

    .left {
      min-width: 200px;
    }

    .right {
      &__main {
        padding-bottom: 27px;

        &.wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          padding-bottom: 0;

          .field {
            flex: 1;

            &__left {
              min-width: 122px;
              flex: 1;
            }

            &__right {
              min-width: 160px;
              display: flex;
              align-items: flex-end;
              gap: 8px;
              flex: 1.2;

              p500-ui-text-field {
                flex: 1;
              }

              button {
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba($g-color-primary, 0.05);
                color: $g-color-primary;
                border-radius: 8px;

                &:hover {
                  background-color: $g-color-primary;
                  color: $g-color-white;
                }
              }
            }
          }
        }
      }
    }
  }
}
